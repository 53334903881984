import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header card-header-stretch" }
const _hoisted_3 = { class: "card-title d-flex align-items-center" }
const _hoisted_4 = { class: "svg-icon svg-icon-1 svg-icon-primary me-3 lh-0" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "tab-content" }
const _hoisted_7 = {
  id: "kt_activity_today",
  class: "card-body p-0 tab-pane fade show active",
  role: "tabpanel",
  "aria-labelledby": "kt_activity_today_tab"
}
const _hoisted_8 = { class: "timeline" }
const _hoisted_9 = {
  id: "kt_activity_week",
  class: "card-body p-0 tab-pane fade show",
  role: "tabpanel",
  "aria-labelledby": "kt_activity_week_tab"
}
const _hoisted_10 = { class: "timeline" }
const _hoisted_11 = {
  id: "kt_activity_month",
  class: "card-body p-0 tab-pane fade show",
  role: "tabpanel",
  "aria-labelledby": "kt_activity_month_tab"
}
const _hoisted_12 = { class: "timeline" }
const _hoisted_13 = {
  id: "kt_activity_year",
  class: "card-body p-0 tab-pane fade show",
  role: "tabpanel",
  "aria-labelledby": "kt_activity_year_tab"
}
const _hoisted_14 = { class: "timeline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTActivityItem1 = _resolveComponent("KTActivityItem1")!
  const _component_KTActivityItem2 = _resolveComponent("KTActivityItem2")!
  const _component_KTActivityItem3 = _resolveComponent("KTActivityItem3")!
  const _component_KTActivityItem4 = _resolveComponent("KTActivityItem4")!
  const _component_KTActivityItem5 = _resolveComponent("KTActivityItem5")!
  const _component_KTActivityItem6 = _resolveComponent("KTActivityItem6")!
  const _component_KTActivityItem7 = _resolveComponent("KTActivityItem7")!
  const _component_KTActivityItem8 = _resolveComponent("KTActivityItem8")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen014.svg" })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "fw-bolder m-0 text-gray-800" }, "Jan 23, 2021", -1))
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-toolbar m-0" }, [
        _createElementVNode("ul", {
          class: "nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder",
          role: "tablist"
        }, [
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("a", {
              id: "kt_activity_today_tab",
              class: "nav-link justify-content-center text-active-gray-800 active",
              "data-bs-toggle": "tab",
              role: "tab",
              href: "#kt_activity_today"
            }, " Today ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("a", {
              id: "kt_activity_week_tab",
              class: "nav-link justify-content-center text-active-gray-800",
              "data-bs-toggle": "tab",
              role: "tab",
              href: "#kt_activity_week"
            }, " Week ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("a", {
              id: "kt_activity_month_tab",
              class: "nav-link justify-content-center text-active-gray-800",
              "data-bs-toggle": "tab",
              role: "tab",
              href: "#kt_activity_month"
            }, " Month ")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("a", {
              id: "kt_activity_year_tab",
              class: "nav-link justify-content-center text-active-gray-800 text-hover-gray-800",
              "data-bs-toggle": "tab",
              role: "tab",
              href: "#kt_activity_year"
            }, " 2021 ")
          ])
        ])
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_KTActivityItem1),
            _createVNode(_component_KTActivityItem2),
            _createVNode(_component_KTActivityItem3),
            _createVNode(_component_KTActivityItem4),
            _createVNode(_component_KTActivityItem5),
            _createVNode(_component_KTActivityItem6),
            _createVNode(_component_KTActivityItem7),
            _createVNode(_component_KTActivityItem8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_KTActivityItem2),
            _createVNode(_component_KTActivityItem3),
            _createVNode(_component_KTActivityItem4),
            _createVNode(_component_KTActivityItem5),
            _createVNode(_component_KTActivityItem6)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_KTActivityItem5),
            _createVNode(_component_KTActivityItem6),
            _createVNode(_component_KTActivityItem8),
            _createVNode(_component_KTActivityItem2),
            _createVNode(_component_KTActivityItem3),
            _createVNode(_component_KTActivityItem4)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_KTActivityItem3),
            _createVNode(_component_KTActivityItem4),
            _createVNode(_component_KTActivityItem5),
            _createVNode(_component_KTActivityItem6)
          ])
        ])
      ])
    ])
  ]))
}